export const STORE_USER_SOURCE_REQUEST = "STORE_USER_SOURCE_REQUEST";
export const STORE_USER_SOURCE_SUCCESS = "STORE_USER_SOURCE_SUCCESS";
export const STORE_USER_SOURCE_FAILURE = "STORE_USER_SOURCE_FAILURE";

export const GET_EVENT_DETAILS_REQUEST = "GET_EVENT_DETAILS_REQUEST";
export const GET_EVENT_DETAILS_SUCCESS = "GET_EVENT_DETAILS_SUCCESS";
export const GET_EVENT_DETAILS_FAILURE = "GET_EVENT_DETAILS_FAILURE";

export const GET_CIRRICULAM_REQUEST = "GET_CIRRICULAM_REQUEST";
export const GET_CIRRICULAM_SUCCESS = "GET_CIRRICULAM_SUCCESS";
export const GET_CIRRICULAM_FAILURE = "GET_CIRRICULAM_FAILURE";

export const GET_UPCOMING_EVENT_DETAILS_REQUEST =
  "GET_UPCOMING_EVENT_DETAILS_REQUEST";
export const GET_UPCOMING_EVENT_DETAILS_SUCCESS =
  "GET_UPCOMING_EVENT_DETAILS_SUCCESS";
export const GET_UPCOMING_EVENT_DETAILS_FAILURE =
  "GET_UPCOMING_EVENT_DETAILS_FAILURE";

export const GET_USER_EVENT_DETAILS_REQUEST = "GET_USER_EVENT_DETAILS_REQUEST";
export const GET_USER_EVENT_DETAILS_SUCCESS = "GET_USER_EVENT_DETAILS_SUCCESS";
export const GET_USER_EVENT_DETAILS_FAILURE = "GET_USER_EVENT_DETAILS_FAILURE";

export const STORE_EVENT_REGISTRATION_REQUEST =
  "STORE_EVENT_REGISTRATION_REQUEST";
export const STORE_EVENT_REGISTRATION_SUCCESS =
  "STORE_EVENT_REGISTRATION_SUCCESS";
export const STORE_EVENT_REGISTRATION_FAILURE =
  "STORE_EVENT_REGISTRATION_FAILURE";
