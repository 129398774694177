export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAILURE = "LOAD_USER_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const GET_PROFILE = "GET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const SET_ACCESS = "SET_ACCESS";
export const CLEAR_ACCESS = "CLEAR_ACCESS";

export const SET_TIMEZONEDATA = "SET_TIMEZONEDATA";
export const CLEAR_TIMEZONEDATA = "CLEAR_TIMEZONEDATA";
