export const GET_EDUCATION_SECTION_REQUEST = "GET_EDUCATION_SECTION_REQUEST";
export const GET_EDUCATION_SECTION_SUCCESS = "GET_EDUCATION_SECTION_SUCCESS";
export const GET_EDUCATION_SECTION_FAILURE = "GET_EDUCATION_SECTION_FAILURE";

export const UPDATE_EDUCATION_SECTION_REQUEST =
  "UPDATE_EDUCATION_SECTION_REQUEST";
export const UPDATE_EDUCATION_SECTION_SUCCESS =
  "UPDATE_EDUCATION_SECTION_SUCCESS";
export const UPDATE_EDUCATION_SECTION_FAILURE =
  "UPDATE_EDUCATION_SECTION_FAILURE";

export const GET_EXTRACURRICULAR_SECTION_REQUEST =
  "GET_EXTRACURRICULAR_SECTION_REQUEST";
export const GET_EXTRACURRICULAR_SECTION_SUCCESS =
  "GET_EXTRACURRICULAR_SECTION_SUCCESS";
export const GET_EXTRACURRICULAR_SECTION_FAILURE =
  "GET_EXTRACURRICULAR_SECTION_FAILURE";

export const UPDATE_EXTRACURRICULAR_SECTION_REQUEST =
  "UPDATE_EXTRACURRICULAR_SECTION_REQUEST";
export const UPDATE_EXTRACURRICULAR_SECTION_SUCCESS =
  "UPDATE_EXTRACURRICULAR_SECTION_SUCCESS";
export const UPDATE_EXTRACURRICULAR_SECTION_FAILURE =
  "UPDATE_EXTRACURRICULAR_SECTION_FAILURE";

export const DELETE_USER_ENTRIES_REQUEST = "DELETE_USER_ENTRIES_REQUEST";
export const DELETE_USER_ENTRIES_SUCCESS = "DELETE_USER_ENTRIES_SUCCESS";
export const DELETE_USER_ENTRIES_FAILURE = "DELETE_USER_ENTRIES_FAILURE";

export const GET_PERSONAL_SECTION_REQUEST = "GET_PERSONAL_SECTION_REQUEST";
export const GET_PERSONAL_SECTION_SUCCESS = "GET_PERSONAL_SECTION_SUCCESS";
export const GET_PERSONAL_SECTION_FAILURE = "GET_PERSONAL_SECTION_FAILURE";

export const UPDATE_PERSONAL_SECTION_REQUEST =
  "UPDATE_PERSONAL_SECTION_REQUEST";
export const UPDATE_PERSONAL_SECTION_SUCCESS =
  "UPDATE_PERSONAL_SECTION_SUCCESS";
export const UPDATE_PERSONAL_SECTION_FAILURE =
  "UPDATE_PERSONAL_SECTION_FAILURE";
