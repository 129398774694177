// //LOGIN ERRORS
export const LOGIN_ERROR_MSG =
  'Something went wrong, if issue persists please contact support'
export const LOGIN_SOCIAL_ERROR_MSG =
  'Unable to login via Social Channel, if issue persists contact supprt team'
export const error = 'Somthing went wrong!'

//OLD STUFF CAN BE REMOVED
// export const errorLogin =
//   'Something went wrong, if issue persists please contact support'
export const notComplete = 'Not Completed, Something Went Wrong!'
export const notMatch = 'Password does not match'
// export const tryAgain = 'Something Went Wrong!!Please try again.'
// export const error = 'Somthing went wrong!'
// export const errorFeedback =
//   'Oops! something went wrong, if this persists please write to our support team'
// export const errorEmailPass = 'Invalid Email or Password'
// export const errorVerifiyEmail = 'Email Not Verified'
// export const errorSocial =
//   'Unable to login via Social Channel, if issue persists contact supprt team'
// export const errorResetPass =
//   'Unable to reset your password. Contact support team'
