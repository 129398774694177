export const HANDLE_PAYMENT_RESPONSE_REQUEST =
  "HANDLE_PAYMENT_RESPONSE_REQUEST";
export const HANDLE_PAYMENT_RESPONSE_SUCCESS =
  "HANDLE_PAYMENT_RESPONSE_SUCCESS";
export const HANDLE_PAYMENT_RESPONSE_FAILURE =
  "HANDLE_PAYMENT_RESPONSE_FAILURE";

export const SUBMIT_PAYMENT_REQUEST = "CREATE_ORDER_REQUEST";
export const SUBMIT_PAYMENT_SUCCESS = "CREATE_ORDER_SUCCESS";
export const SUBMIT_PAYMENT_FAILURE = "CREATE_ORDER_FAILURE";

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
