export const GET_UPCOMING_LIVE_STREAMS_REQUEST =
  "GET_UPCOMING_LIVE_STREAMS_REQUEST";
export const GET_UPCOMING_LIVE_STREAMS_SUCCESS =
  "GET_UPCOMING_LIVE_STREAMS_SUCCESS";
export const GET_UPCOMING_LIVE_STREAMS_FAILURE =
  "GET_UPCOMING_LIVE_STREAMS_FAILURE";

export const GET_PREVIOUS_LIVE_STREAMS_REQUEST =
  "GET_PREVIOUS_LIVE_STREAMS_REQUEST";
export const GET_PREVIOUS_LIVE_STREAMS_SUCCESS =
  "GET_PREVIOUS_LIVE_STREAMS_SUCCESS";
export const GET_PREVIOUS_LIVE_STREAMS_FAILURE =
  "GET_PREVIOUS_LIVE_STREAMS_FAILURE";
