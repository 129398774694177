export const GET_COUNTRY_ARRAY_REQUEST = "GET_COUNTRY_ARRAY_REQUEST";
export const GET_COUNTRY_ARRAY_SUCCESS = "GET_COUNTRY_ARRAY_SUCCESS";
export const GET_COUNTRY_ARRAY_FAILURE = "GET_COUNTRY_ARRAY_FAILURE";

export const GET_STATE_ARRAY_BY_COUNTRYID_INSIDE_REQUEST =
  "GET_STATE_BY_COUNTRYID_INSIDE_REQUEST";
export const GET_STATE_ARRAY_BY_COUNTRYID_INSIDE_SUCCESS =
  "GET_STATE_BY_COUNTRYID_INSIDE_SUCCESS";
export const GET_STATE_ARRAY_BY_COUNTRYID_INSIDE_FAILURE =
  "GET_STATE_BY_COUNTRYID_INSIDE_FAILURE";

export const GET_CITY_ARRAY_BY_STATEID_INSIDE_REQUEST =
  "GET_CITY_BY_STATEID_INSIDE_REQUEST";
export const GET_CITY_ARRAY_BY_STATEID_INSIDE_SUCCESS =
  "GET_CITY_BY_STATEID_INSIDE_SUCCESS";
export const GET_CITY_ARRAY_BY_STATEID_INSIDE_FAILURE =
  "GET_CITY_BY_STATEID_INSIDE_FAILURE";

export const GET_STATE_ARRAY_BY_COUNTRYID_HUNDRET_REQUEST =
  "GET_STATE_BY_COUNTRYID_HUNDRET_REQUEST";
export const GET_STATE_ARRAY_BY_COUNTRYID_HUNDRET_SUCCESS =
  "GET_STATE_BY_COUNTRYID_HUNDRET_SUCCESS";
export const GET_STATE_ARRAY_BY_COUNTRYID_HUNDRET_FAILURE =
  "GET_STATE_BY_COUNTRYID_HUNDRET_FAILURE";
